import {useParams, useHistory} from 'react-router-dom';
import {Flex, Text} from 'rebass';

import {
  useConsolidatedEntityById,
  useFinancingClients,
  useContractorProjectsV2,
  useDaasClientById,
} from '@renofi/api';
import {_Loader} from '@renofi/components';
import {_red} from '@renofi/utils';

import {Layout} from '../../../components';
import {CLIENT_TYPES} from '../../../constants';
import CddReviewStatus from '../CddReviewStatus';
import QuestionnaireFinishedModal from '../QuestionnaireFinishedModal';

import ClientSummary from './ClientSummary';
import ProjectDocuments from './ProjectDocuments';

const CLIENT_TO_ENTITY_TYPE = {
  design: CLIENT_TYPES.DAAS_CLIENT,
  contractor: CLIENT_TYPES.CONTRACTOR_CLIENT,
  project: CLIENT_TYPES.PROJECT,
};

const ClientDetails = () => {
  const history = useHistory();
  const {clientId, clientType} = useParams();
  const {consolidatedEntity, loading} = useConsolidatedEntityById({
    variables: {
      ...(clientType ? {entityId: clientId} : {id: clientId}),
      entityType: CLIENT_TO_ENTITY_TYPE[clientType],
    },
  });

  const {collection: contractorClients} = useFinancingClients({
    skip:
      !consolidatedEntity?.entityId ||
      consolidatedEntity?.normalizedEntityType !==
        CLIENT_TYPES.CONTRACTOR_CLIENT,
    variables: {limit: 1000},
  });

  const contractorClient = contractorClients.find(
    ({id}) => consolidatedEntity?.entityId === id,
  );

  const {projects} = useContractorProjectsV2({
    skip:
      !consolidatedEntity?.entityId ||
      consolidatedEntity?.normalizedEntityType !== CLIENT_TYPES.PROJECT,
  });

  const projectClient = projects.find(
    ({id}) => consolidatedEntity?.entityId === id,
  );

  const {daasClient: designClient} = useDaasClientById({
    skip:
      !consolidatedEntity?.entityId ||
      consolidatedEntity?.normalizedEntityType !== CLIENT_TYPES.DAAS_CLIENT,
    variables: {
      id: consolidatedEntity?.entityId,
    },
  });

  const client = contractorClient || projectClient || designClient || {};

  return (
    <Layout.AuthenticatedPage
      title={
        <>
          {!loading && (
            <Flex role="page_title" alignItems="baseline">
              <Layout.BackArrow size={24} onClick={() => history.goBack()} />
              <Text fontSize={[32, 40]} lineHeight="normal" fontWeight={700}>
                {consolidatedEntity?.entityFullName}
              </Text>
            </Flex>
          )}
        </>
      }>
      <Flex flexDirection="column" css={{gap: 32}}>
        <ClientSummary
          client={client}
          consolidatedEntity={consolidatedEntity}
        />
        {consolidatedEntity?.normalizedEntityType === 'project' && (
          <ProjectDocuments projectId={projectClient?.id} />
        )}
        <CddReviewStatus />

        <QuestionnaireFinishedModal />
      </Flex>
    </Layout.AuthenticatedPage>
  );
};

export default ClientDetails;
