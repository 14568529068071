import {noop} from 'lodash';

import {
  useAuthenticate,
  useAuthenticateByLoginToken,
  useCurrentUser,
} from '@renofi/api';
import {useNotifications} from '@renofi/components';
import {removeJwt, removeRefreshToken} from '@renofi/utils';
import {UTM_COOKIE_KEYS} from '@renofi/utils/src/const';
import {deleteAllCookies} from '@renofi/utils/src/cookies';

import {stopAuthTokenRefresh} from './utils';
import useCompleteAuth from './useCompleteAuth';
import {LOGIN_ERROR} from './constants';

export default () => {
  const {removeUser} = useCurrentUser({lazy: true});
  const {authenticateByLoginToken, loading: tokenAuthLoading} =
    useAuthenticateByLoginToken();
  const {authenticate: authenticateByEmail, loading: emailAuthLoading} =
    useAuthenticate();
  const addNotification = useNotifications();
  const completeAuth = useCompleteAuth();

  const loading = tokenAuthLoading || emailAuthLoading;

  const login = async ({
    token,
    email,
    password,
    onAuthSuccess = noop,
    onAuthFail = noop,
  }) => {
    const resultDataKey = token ? 'authenticateByLoginToken' : 'authenticate';

    let rsp;
    try {
      if (token) {
        rsp = await authenticateByLoginToken({variables: {token}});
      } else {
        rsp = await authenticateByEmail({variables: {email, password}});
      }
      const {jwt, refreshToken, user} = rsp?.data?.[resultDataKey] || {};

      if (!jwt || !refreshToken) {
        return addNotification(LOGIN_ERROR);
      }

      completeAuth({jwt, refreshToken, user});

      onAuthSuccess();
    } catch (err) {
      addNotification(LOGIN_ERROR);
      onAuthFail(err);
      return err;
    }

    return rsp;
  };

  const logout = async () => {
    await removeUser();
    deleteAllCookies({exceptions: Object.values(UTM_COOKIE_KEYS)});
    removeJwt();
    removeRefreshToken();
    stopAuthTokenRefresh();
  };
  return {loading, login, logout};
};
