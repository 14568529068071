import {getDomain, setItem} from '../cookies';

export default function removeJwt() {
  setItem({
    key: 'jwt',
    value: '',
    numberOfDays: -1,
    domain: getDomain(),
  });
}
