export const ONE_SECOND_MS = 1000;

export const ONE_MINUTE = ONE_SECOND_MS * 60;

export const THIRTY_SECONDS = ONE_MINUTE * 0.5;

export const LOGIN_ERROR = {
  type: 'error',
  message: 'There was a problem logging in',
};

export const REGISTRATION_ERRORS = {
  GENERIC_ERROR: {
    type: 'error',
    message: 'There was a problem in the registration.',
  },
  TOKEN_EXPIRED_ERROR: {
    type: 'error',
    message:
      'Thе registration token has expired. Please request a new one by entering your email again and clicking the Sign up for free button.',
  },
};
