import React, {useContext, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {Box, Flex, Text} from 'rebass';
import {useHistory, useLocation} from 'react-router-dom';

import {stopAuthTokenRefresh} from '@renofi/modules';
import {deleteAllCookies} from '@renofi/utils/src/cookies';
import {Loader, Toggle} from '@renofi/components';
import {getItem, useScreenSize} from '@renofi/utils';
import {red} from '@renofi/utils/src/colors';

import {
  ONBOARDING_PATH,
  REQUIRED_AGREEMENTS,
  ACCOUNT_SETUP_PATHS,
} from '../../constants';
import AppContext from '../../context';
import TermsOfService from '../TermsOfService';

import Navbar from './Navbar';

export const hasAcceptedAgreements = (user = {}) => {
  const currentAgreements = user.currentAgreements || [];
  if (!currentAgreements.length) {
    return false;
  }

  return REQUIRED_AGREEMENTS.every((agreement) => {
    const isAgreementPresent = currentAgreements.some(
      ({name}) => name === agreement,
    );

    return isAgreementPresent;
  });
};

/**
 * @description Temp: I reckon if we go React native we might want contextual "View" here between web and native
 */
const AuthenticatedPage = ({
  fullScreen = false,
  noPadding,
  children,
  css,
  maxWidth = 1344,
  width,
  pb = 80,
  mt = 4,
  title,
}) => {
  const {isMobile} = useScreenSize();
  const {isLoggedIn, isOnboardingRequired, loading, user} =
    useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const [showAgreements, setShowAgreements] = useState(false);
  const [agreementsHaveChanged, setAgreementsHaveChanged] = useState(false);

  useEffect(() => {
    const jwt = getItem('jwt');
    const refreshToken = getItem('refreshToken');
    if (!jwt || !refreshToken) {
      deleteAllCookies();
      stopAuthTokenRefresh();
      history.push('/');
    }
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      return setShowAgreements(false);
    }

    const currentAgreements = user?.currentAgreements || [];
    if (!currentAgreements.length) {
      return setShowAgreements(true);
    }

    const acceptedAgreements = currentAgreements.filter(({name}) =>
      REQUIRED_AGREEMENTS.includes(name),
    );

    // Mismatch in length infers a required agreement has changed.
    if (acceptedAgreements.length !== REQUIRED_AGREEMENTS.length) {
      setAgreementsHaveChanged(true);
      return setShowAgreements(true);
    }

    if (
      !Object.values(ACCOUNT_SETUP_PATHS).includes(location.pathname) &&
      isOnboardingRequired
    ) {
      history.push(ONBOARDING_PATH);
    }
  }, [isLoggedIn, user?.id]);

  return (
    <>
      <Toggle show={loading}>
        <Loader color={red} />
      </Toggle>

      <Toggle show={!loading && isLoggedIn}>
        {!fullScreen ? <Navbar /> : null}

        <Flex
          justifyContent="space-between"
          css={css}
          maxWidth={maxWidth}
          width={width ? width : '100%'}
          minHeight="100%"
          flexDirection="column"
          p={noPadding || fullScreen ? 0 : isMobile ? '24px 20px' : 4}
          pb={pb}>
          {Boolean(title) ? (
            React.isValidElement(title) ? (
              title
            ) : (
              <Text role="page_title" fontSize={[32, 40]} as="h1">
                {title}
              </Text>
            )
          ) : null}
          <Box mt={mt} height="100%">
            {children}
          </Box>
        </Flex>
      </Toggle>

      {showAgreements ? (
        <TermsOfService
          agreementsHaveChanged={agreementsHaveChanged}
          onClose={() => setShowAgreements(false)}
        />
      ) : null}
    </>
  );
};

AuthenticatedPage.propTypes = {
  noPadding: PropTypes.bool,
  children: PropTypes.any,
  css: PropTypes.object,
  pb: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default AuthenticatedPage;
