import {gql} from '@apollo/client';

const taskTemplate = `
details
facet
financial
id
informationKind
renovationUnderwriting
requirements
taskType
title
`;

export default gql`
  query taskOptionsByLoanTypeOrLender($lenderId: ID!, $loanType: LoanType!, $projectId: ID!) {
    taskOptionsByLoanTypeOrLender(lenderId: $lenderId, loanType: $loanType, projectId: $projectId) {
      tasksToAdd {
        ${taskTemplate}
      }
      tasksToRemove {
        ${taskTemplate}
      }
    }

  }

`;
