export default `
  id
  email
  firstName
  lastName
  phoneNumber
  propertyAddress
  propertyZipCode
  renovationCost
  estimatedCurrentPropertyValue
  estimateEmailLastSharedAt
  scenarioId
  scenarioDashboardLink
  scenarioDashboardLinkQrCodeUrl
  createdAt
  updatedAt
  hasInterestInDesign
`;
