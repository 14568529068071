import * as Sentry from '@sentry/react';
import {isObject, isArray} from 'lodash';

import {SESSION_FEATURE_FLAGS_STORAGE_KEY} from '@renofi/utils/src/const';
import {getLeadCreditScore} from '@renofi/utils/src/lead';
import {getItem} from '@renofi/utils/src/cookies';
import logger from '@renofi/utils/src/logger';
import {UTM_COOKIE_KEYS} from '@renofi/utils/src/const';
import {formatName} from '@renofi/utils/src/format';

import {setDataDogUser} from './datadog';
import LogRocket, {identifyLogRocket} from './logrocket2';

export const execute = (func) => {
  try {
    func();
  } catch (error) {
    logger.error('ERROR', error);
  }
};

export const defaultContext = {
  context: {
    ip: '0.0.0.0',
  },
};

export const isTestSession = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const testParam = urlParams.has('test');
  const testStorage = JSON.parse(localStorage.getItem('test'));

  const isTestStorageEnabled = testStorage && testStorage !== 'false';
  const isTestParamEnabled = testStorage && urlParams.get('test') !== 'false';

  if (testParam) {
    localStorage.setItem('test', urlParams.get('test'));
  }

  return isTestParamEnabled || isTestStorageEnabled;
};

export const isForcingUTM = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const param = urlParams.has('forceUtm');
  return isTestSession() || (param && urlParams.get('forceUtm') !== 'false');
};

export const isCookieConsentGiven = () => {
  const cookieName = UTM_COOKIE_KEYS['cookieconsent'];
  return getItem(cookieName);
};

export const getCampaignByEventName = (event) => {
  const split = event?.split('/');
  const prefix = isArray(split) ? split[0] : '';
  const lowerPrefix = prefix?.toLowerCase();

  const cookieName = UTM_COOKIE_KEYS[lowerPrefix];
  const cookie = getItem(cookieName);

  return cookie ? JSON.parse(cookie) : null;
};

export const getEventData = (event, options = {}) => {
  const BRANCH = process.env.REACT_APP_BRANCH || process.env.NEXT_PUBLIC_BRANCH;
  const campaign = getCampaignByEventName(event);
  let parsedFeatureFlags = {};

  const sessionFeatureFlags = sessionStorage.getItem(
    SESSION_FEATURE_FLAGS_STORAGE_KEY,
  );

  if (sessionFeatureFlags) {
    parsedFeatureFlags = JSON.parse(sessionFeatureFlags);
  }

  return {
    ...parsedFeatureFlags,
    ...(window.analyticsTraits ? window.analyticsTraits : {}),
    ...gaParams(event),
    ...(isObject(campaign) ? {campaign} : {}),
    ...options,
    branch: BRANCH,
  };
};

export const sendEvent = (event, options = {}) => {
  const GA_ID = process.env.REACT_APP_GA_ID || process.env.NEXT_PUBLIC_GA_ID;

  execute(() => {
    const data = getEventData(event, options);

    logger.debug('analytics.track', event, data);

    if (isCookieConsentGiven()) {
      window.analytics?.track(event, data);
    } else {
      window.analytics?.track(event, data, defaultContext);
    }
    LogRocket?.track(event, data);
    window.gtag &&
      window.gtag('event', 'page_view', {
        send_to: GA_ID,
        dynx_pagetype: `{{${event}}}`,
      });
  });
};

export const page = () => {
  execute(() => window.analytics?.page(gaParams()));
};

export const setSentryUser = (id, data = {}) => {
  const username = formatName(data);

  Sentry.setUser({
    id,
    username,
    ...data,
  });
};

export const setAnalyticsIdentify = (lead, data = {}) => {
  const ENV = process.env.REACT_APP_ENV || process.env.NEXT_PUBLIC_ENV;
  const BRANCH = process.env.REACT_APP_BRANCH || process.env.NEXT_PUBLIC_BRANCH;
  const {id, email, firstName, lastName, stars} = lead;

  const simplifiedLoanProducts = data?.loanProducts?.map((product) => ({
    name: product.name,
    productType: product.productType,
    maxPeakCltv: product.maxPeakCltv,
    maxLoanAmount: product.maxLoanAmount,
    displayedLoanAmount: product.offeredLoanAmountExcludingRefinance,
    calculatedMaxLoanAmount: product.calculatedMaxLoanAmount,
    calculatedMinMonthlyPayment: product.calculatedMinMonthlyPayment,
    maxDti: product.maxDti,
    rates: product.rates,
    interestOnly: product.interestOnly,
    lenderName: product.lender?.name,
    terms: product.terms,
  }));
  const traits = {
    ...lead,
    ...gaParams(),
    branch: BRANCH,
    creditScore: getLeadCreditScore(lead),
    ...(stars ? {starRank: stars} : {}),
    ...data,
    loanProducts: simplifiedLoanProducts,
  };
  window.analyticsTraits = traits;

  execute(() => {
    window.analytics?.identify(id, traits);
    identifyLogRocket(email, {
      id,
      name: `${firstName} ${lastName}`,
      ...traits,
    });
    setSentryUser(id, traits);
    setDataDogUser({id, email, name: formatName(lead)});

    if (ENV === 'staging' || ENV === 'development') {
      // eslint-disable-next-line
      console.count('Identity call');
      logger.log('Identity traits', traits);
    }
  });
};

export const logRocketSessionURL = () => {
  let url = null;
  execute(() => {
    url = LogRocket.sessionURL;
  });
  return url;
};

export const logGraphQLError = ({graphQLErrors, operation, networkError}) => {
  const {operationName, variables} = operation || {};
  const extra = {operationName, variables};

  if (graphQLErrors)
    graphQLErrors.forEach(({message, locations, path, extensions}) => {
      const err = `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
        locations,
      )}, Path: ${path}, Raw: ${JSON.stringify(extensions)}`;
      Sentry.captureException(err, {extra});
    });
  if (networkError) {
    const err = `[Network error]: ${JSON.stringify(networkError)}`;
    Sentry.captureException(err, {extra});
  }
};

function gaClientId() {
  const cookie = getItem('_ga');
  if (typeof cookie === 'string') {
    const split = cookie.split('.');
    return split?.length > 1 ? split.slice(-2).join('.') : '';
  }
  return '';
}

function gaSessionId() {
  const cookie = getItem('_ga_RTCZTCBR91');
  if (typeof cookie === 'string') {
    const split = cookie.split('.');
    return split?.length > 2 ? split[2] : '';
  }
  return '';
}

export function gaParams(event) {
  const clientId = gaClientId();
  const sessionId = gaSessionId();
  const sessionCookie = getItem('_ga_RTCZTCBR91');

  return {
    ...(clientId ? {gaClientId: clientId} : {}),
    ...(sessionId ? {gaSessionId: sessionId} : {}),
    ...(sessionCookie ? {gaSessionCookie: sessionCookie} : {}),
    ...(event ? {gaName: event?.replace(/[/.-]/g, '_')} : {}),
  };
}
