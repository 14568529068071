export default `
    id
    calculatorToken
    additionalRenovationDetails
    firstName
    borrowerRegistrationCompletedAt
    convertedAt
    hasNewsletterConsent
    afterRenovatedValue
    homeValue
    hasRenovationEstimate
    estimatedHomeValue
    propertyPurchasePrice
    estimatedDownpayment
    firstMortgageBalance
    firstMortgageRate
    renovationCost
    loanAmount
    loanAmountDesiredForRenovation
    creditScore
    selfReportedCreditScore
    realCreditScoreNumber
    medal
    monthlyIncomeAlimony
    monthlyIncomeChildSupport
    monthlyDebt
    stars
    state
    estimatedDti
    prequalStartedAt
    prequalCompletedAt
    prequalConditions
    prequalFailures
    prequalResult
    alimonyPayment
    childSupportPayment
    isLivingInPropertyDuringReno
    loanSubject
    alreadyStartedPropertyStateDetails
    annualIncomePretax
    creditCardPayment
    hasLateMortgagePayments
    primaryMortgagePayment
    propertyOwnershipStatus
    secondaryMortgagePayment
    secondMortgageBalance
    personalLoanPayment
    propertyStructureType
    propertyUseType
    recentBankruptcy
    renovationIdealStart
    renovationPerformedByType
    selfReportedOriginType
    selfReportedOriginDetails
    studentLoanPayment
    studentLoanBalance
    studentLoanPrePandemicPayment
    subcontractorsSecured
    isSubcontractingMany
    isLicensedContractor
    inLicensedState
    vehiclePayment
    tcpaConsentAt
    createdAt
    updatedAt
    updatedByUserAt
    totalMortgageBalance
    estimatedProjectSize
    sourceLenderCode
    leadExternalOriginType
    demolishExistingStructure
    preExistingStructuresOnLandPresent
    anythingLeftFromOriginalStructure
    teardownOfExistingStructure
    utmCampaign
    utmContent
    utmMedium
    utmSource
    utmTerm
    brokerSourced
    dashboardLastVisitedAt
    contractorRequestedAt
    leadSourceCrmName
    experimentalId
    dashboardNextStep
`;
