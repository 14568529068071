import {Box, Image} from 'rebass';

import postcardAssetSrc from '../img/postcard-asset.svg';
import coBrandedPostcardAssetSrc from '../img/co-branded-postcard-asset.svg';
import qrCodeSrc from '../img/default-qr-code.png';

export const METADATA = {
  id: 'postcard-flyer',
  pdfWidth: 152.4,
  pdfHeight: 101.6,
  fileName: 'postcard-flyer',
  orientation: 'landscape',
};

const PostcardFlyer = ({qrLink, logoUrl}) => {
  return (
    <Box id={METADATA.id} width={576} height={384} css={{position: 'relative'}}>
      {!logoUrl && (
        <>
          <Image
            size={58}
            src={qrLink || qrCodeSrc}
            css={{position: 'absolute', top: 265, right: 245}}
          />
          <Image src={postcardAssetSrc} />
        </>
      )}
      {logoUrl && (
        <>
          <Image
            size={58}
            src={qrLink || qrCodeSrc}
            css={{position: 'absolute', top: 265, right: 71}}
          />
          <Box
            width={288}
            height={155}
            css={{
              position: 'absolute',
              top: 51,
              left: 0,
            }}>
            <Image
              role="postcard-flyer-logo"
              width={159}
              height="auto"
              src={logoUrl}
              css={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          </Box>
          <Image src={coBrandedPostcardAssetSrc} />
        </>
      )}
    </Box>
  );
};

export default PostcardFlyer;
