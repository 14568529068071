import {useEffect} from 'react';

import {useParams, useHistory} from 'react-router-dom';
import {Flex} from 'rebass';

import {Loader} from '@renofi/components';
import {useLogin} from '@renofi/modules';
import {sendEvent} from '@renofi/analytics';
import {red} from '@renofi/utils';

import {AUTH_PATHS} from '../constants';

const CompleteLogin = () => {
  const history = useHistory();
  const {token} = useParams();
  const {login} = useLogin();

  const onAuthSuccess = () => {
    sendEvent('Contractors/User-Logged-In-Successfully');
    history.push('/');
  };

  const onAuthFail = (err) => {
    sendEvent('Contractors/User-Login-Attempt-Failed', {err});
    history.push(AUTH_PATHS.LOGIN);
  };

  useEffect(() => {
    login({token, onAuthSuccess, onAuthFail});
  }, []);

  return (
    <Flex
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center">
      <Loader color={red} size={2} />
    </Flex>
  );
};

export default CompleteLogin;
