import {useState, useEffect, useContext} from 'react';

import {Flex, Box} from 'rebass';
import {pick, pickBy, isEmpty} from 'lodash';

import {sendEvent} from '@renofi/analytics';
import {
  Modal,
  Label,
  TextField,
  PhoneField,
  AddressField,
  StatesField,
  Textarea,
  Button,
  useNotifications,
} from '@renofi/components';
import {blueGray, gray, formatPhoneNumber} from '@renofi/utils';
import {
  useReferClientForDaas,
  useReferClientForFinancing,
  GET_DAAS_CLIENT_BY_ID,
  GET_FINANCING_CLIENTS,
  GET_CONSOLIDATED_ENTITY_BY_ID,
} from '@renofi/api';

import AppContext from '../../../context';
import {Layout, EmailField, ClientModalCheckbox} from '../../../components';
import {CLIENT_TYPES} from '../../../constants';

const CLIENT_KEYS = [
  'id',
  'firstName',
  'lastName',
  'email',
  'phoneNumber',
  'projectDetails',
  'interestedIn',
  'hasInterestInFinancing',
  'hasInterestInDesign',
];

const EditClientModal = ({client, consolidatedEntity, onCancel}) => {
  const {contractor} = useContext(AppContext);
  const [clientDetails, setClientDetails] = useState({});
  const [propertyAddress, setPropertyAddress] = useState('');
  const [propertyState, setPropertyState] = useState('');
  const [propertyZipCode, setPropertyZipCode] = useState('');
  const {referClientForDaas, loading: daasLoading} = useReferClientForDaas({
    refetchQueries: [GET_CONSOLIDATED_ENTITY_BY_ID, GET_DAAS_CLIENT_BY_ID],
  });
  const {referClientForFinancing, loading: financingLoading} =
    useReferClientForFinancing({
      refetchQueries: [GET_CONSOLIDATED_ENTITY_BY_ID, GET_FINANCING_CLIENTS],
    });
  const addNotification = useNotifications();

  const isDesignClient =
    consolidatedEntity?.normalizedEntityType === CLIENT_TYPES.DAAS_CLIENT;

  const isFinancingEstimate = client?.estimate;

  const isSubmitting = daasLoading || financingLoading;

  const hasAnyChanges =
    CLIENT_KEYS.some((key) => {
      if (key === 'phoneNumber') {
        return (
          formatPhoneNumber(clientDetails[key]) !==
          formatPhoneNumber(client[key], {stripCountryCode: true})
        );
      }
      return client[key] !== clientDetails[key];
    }) ||
    client?.propertyAddress !== propertyAddress ||
    client?.propertyZipCode !== propertyZipCode ||
    client?.propertyState !== propertyState;

  const updateClient = (clientDetails) => {
    if (isDesignClient) {
      return referClientForDaas({
        variables: {contractorId: contractor.id, client: clientDetails},
      });
    }

    return referClientForFinancing({variables: {client: clientDetails}});
  };

  useEffect(() => {
    if (client) {
      setClientDetails(pick(client, CLIENT_KEYS));
      setPropertyAddress(client.propertyAddress);
      setPropertyState(client.propertyState);
      setPropertyZipCode(client.propertyZipCode);
    }
  }, [client]);

  const onValueChange = (key) => (value) => {
    setClientDetails({...clientDetails, [key]: value});
  };

  function onSearchValueChange(value) {
    if (!value) {
      setPropertyAddress('');
      setPropertyState('');
      setPropertyZipCode('');
      return;
    }
    setPropertyAddress(value);
  }

  const onAddressSelect = (newLocation) => {
    const address = [newLocation?.streetAddress, newLocation?.city]
      .filter(Boolean)
      .join(', ');
    setPropertyAddress(address);
    setPropertyZipCode(newLocation.zipCode);
    setPropertyState(newLocation.stateCode);
  };

  const onSubmit = async () => {
    const updateValues = pickBy(
      {
        ...clientDetails,
        propertyAddress,
        propertyState,
        propertyZipCode,
      },
      (value) => !isEmpty(value),
    );
    try {
      await updateClient({id: client.id, ...updateValues});
      sendEvent('Contractors/Client-Updated-Successfully', {
        ...clientDetails,
      });
      onCancel();
    } catch (e) {
      sendEvent('Contractors/Updating-Client-Failed', {
        message: e.message,
      });
      addNotification({
        type: 'error',
        message: 'There was a updating the client.',
      });
    }
  };

  return (
    <Modal
      show
      light
      hideCloseButton
      contentCss={{width: 740, paddingBottom: 0}}>
      <Layout.PanelTitleV2>Summary</Layout.PanelTitleV2>
      <Layout.PanelInnerWrapper>
        <Flex
          flexDirection="column"
          padding={['28px 16px', '32px 40px']}
          css={{
            borderTop: `1px solid ${blueGray}`,
          }}>
          <Flex css={{gap: 24}}>
            <Box flex={1}>
              <Label small htmlFor="firstName">
                First name
              </Label>
              <TextField
                id="firstName"
                value={clientDetails?.firstName}
                onChange={onValueChange('firstName')}
              />
            </Box>
            <Box flex={1}>
              <Label small htmlFor="lastName">
                Last name
              </Label>
              <TextField
                id="lastName"
                value={clientDetails?.lastName}
                onChange={onValueChange('lastName')}
              />
            </Box>
          </Flex>
          <Flex my={24} css={{gap: 24}}>
            <Box flex={1}>
              <Label fontSize={14} color={gray} htmlFor="email">
                Email address
              </Label>
              <EmailField
                id="email"
                placeholder={null}
                value={clientDetails?.email}
                onChange={onValueChange('email')}
              />
            </Box>
            <Box flex={1}>
              <Label fontSize={14} color={gray} htmlFor="phoneNumber">
                Phone number
              </Label>
              <PhoneField
                id="phoneNumber"
                topMessage="optional"
                stripCountryCode
                value={clientDetails?.phoneNumber}
                onChange={onValueChange('phoneNumber')}
              />
            </Box>
          </Flex>

          {isFinancingEstimate && (
            <AddressField
              autofocus={false}
              label="Address"
              placeholder="Search for address"
              value={propertyAddress}
              onChange={onSearchValueChange}
              onSelect={onAddressSelect}
            />
          )}

          {!isFinancingEstimate && (
            <Box flex={1}>
              <Label fontSize={14} color={gray} htmlFor="propertyState">
                Property state
              </Label>
              <StatesField
                id="propertyState"
                value={propertyState}
                onChange={setPropertyState}
              />
            </Box>
          )}

          <Box width="100%" mt={32}>
            <Label fontSize={14} color={gray} htmlFor="projectDetails">
              Enter a brief description of the project
            </Label>
            <Textarea
              height={143}
              id="projectDetails"
              value={clientDetails?.projectDetails}
              onChange={onValueChange('projectDetails')}
            />
          </Box>
          {!isDesignClient && (
            <Box mt={32}>
              <ClientModalCheckbox
                mt={32}
                id="consentGiven"
                name="consentGiven"
                checked={clientDetails?.hasInterestInDesign}
                onChange={() =>
                  onValueChange('hasInterestInDesign')(
                    !clientDetails?.hasInterestInDesign,
                  )
                }>
                This client is interested in RenoFi’s design service.
              </ClientModalCheckbox>
            </Box>
          )}
          {isDesignClient && (
            <Box mt={32}>
              <ClientModalCheckbox
                mt={32}
                id="hasInterestInFinancing"
                name="hasInterestInFinancing"
                checked={clientDetails?.hasInterestInFinancing}
                onChange={() =>
                  onValueChange('hasInterestInFinancing')(
                    !clientDetails?.hasInterestInFinancing,
                  )
                }>
                I would also like RenoFi to contact this client about financing
                options for their renovation.
              </ClientModalCheckbox>
            </Box>
          )}
        </Flex>
        <Layout.PanelCTAWrapper small>
          <Button secondary xSmall onClick={onCancel}>
            Cancel
          </Button>
          <Button
            xSmall
            css={{marginLeft: 16}}
            disabled={isSubmitting || !hasAnyChanges}
            loading={isSubmitting}
            onClick={onSubmit}>
            Save details
          </Button>
        </Layout.PanelCTAWrapper>
      </Layout.PanelInnerWrapper>
    </Modal>
  );
};

export default EditClientModal;
