import React from 'react';

import {Route, Switch} from 'react-router-dom';

import {useProjectRedirectById} from '../api';
import {Layout} from '../components';
import CTATopBar from '../components/CTATopBar';

import Clients from './components/Clients';
import ClientDetails from './components/ClientDetails';
import Questionnaire from './components/Questionnaire';
import RequestClientFinancingModal from './components/RequestClientFinancingModal';

const Financing = () => {
  useProjectRedirectById();

  return (
    <>
      <Switch>
        <Route exact path="/financing/:projectId/questionnaire/:step?">
          <Layout.AuthenticatedPage title={<CTATopBar title="Your Clients" />}>
            <Questionnaire />
          </Layout.AuthenticatedPage>
        </Route>

        <Route exact path="/financing/client/:clientId/:clientType?">
          <ClientDetails />
        </Route>

        <Route>
          <Layout.AuthenticatedPage title={<CTATopBar title="Your Clients" />}>
            <Clients />
          </Layout.AuthenticatedPage>
        </Route>
      </Switch>
      <RequestClientFinancingModal />
    </>
  );
};

export default Financing;
