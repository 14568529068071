import {gql} from '@apollo/client';

export default gql`
  query financingClients($limit: Int, $page: Int) {
    financingClients(limit: $limit, page: $page) {
      collection {
        createdAt
        email
        firstName
        id
        lastName
        leadStatus
        phoneNumber
        propertyAddress
        propertyState
        propertyZipCode
        updatedAt
        projectDetails
        hasInterestInDesign
        estimate
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;
