import React, {useEffect, useState} from 'react';

import {Text} from 'rebass';

import {sendEvent} from '@renofi/analytics';
import {ArrowIcon, CopyToClipboard} from '@renofi/components';

import {EMAIL_ADDRESS, PHONE_NUMBER} from '../../../constants';
import {useContactUsCallbacks} from '../../../utils';

import {ReactComponent as ArchitectureIcon} from './img/architecture.svg';
import {ReactComponent as BoltIcon} from './img/bolt.svg';
import {ReactComponent as CalculatorIcon} from './img/calculator.svg';
import {ReactComponent as CalculatorIconWhite} from './img/calculator_white.svg';
import {ReactComponent as ClientsIcon} from './img/clients.svg';
import {ReactComponent as MenuIcon} from './img/menu.svg';
import {ReactComponent as PhoneIcon} from './img/phone_android.svg';
import {ReactComponent as QrCodeScannerIcon} from './img/qr_code_scanner_white.svg';
import {
  BottomNavMobile,
  MoreOptionsMobile,
  MoreOptionsMobileLink,
  NavbarMobileLink,
} from './styled';

const NavbarMobile = ({user}) => {
  const [showMore, setShowMore] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const {onClickCalendarLink, onClickEmail, onClickIntercom, onClickPhone} =
    useContactUsCallbacks({user});

  const isActive = (path) => (match) => match?.url?.includes(path);

  const onClickShowMore = (e) => {
    e.preventDefault();
    setShowMore(!showMore);
    sendEvent('Contractors/ShowMore-Clicked');
  };

  const onClickShowContacts = (e) => {
    e.preventDefault();
    setShowContacts(!showContacts);
    sendEvent('Contractors/ShowContacts-Clicked');
  };

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('update', {
        hide_default_launcher: showMore,
      });
    }
  }, [showMore]);

  return (
    <>
      {showMore ? (
        <MoreOptionsMobile>
          <MoreOptionsMobileLink
            isActive={() => showContacts}
            onClick={onClickShowContacts}
            role="mobile_nav_contact_btn"
            to="">
            <PhoneIcon />
            Contact us
            <ArrowIcon
              style={{marginLeft: 'auto'}}
              direction={showContacts ? 'up' : 'down'}
              size={16}
            />
          </MoreOptionsMobileLink>
          {showContacts ? (
            <>
              <MoreOptionsMobileLink
                style={{opacity: 0.6}}
                onClick={onClickPhone}
                to="">
                Call {PHONE_NUMBER}
              </MoreOptionsMobileLink>

              <MoreOptionsMobileLink
                style={{opacity: 0.6}}
                onClick={onClickCalendarLink}
                to="">
                Schedule a call
              </MoreOptionsMobileLink>

              <MoreOptionsMobileLink
                style={{justifyContent: 'space-between'}}
                onClick={onClickEmail}
                to="">
                <Text css={{opacity: 0.6}}>{EMAIL_ADDRESS}</Text>
                <CopyToClipboard
                  textToCopy={EMAIL_ADDRESS}
                  successMessage="Email copied!"
                  fontSize={14}>
                  Copy email
                </CopyToClipboard>
              </MoreOptionsMobileLink>

              <MoreOptionsMobileLink
                style={{opacity: 0.6}}
                onClick={onClickIntercom}
                to="">
                Chat
              </MoreOptionsMobileLink>
            </>
          ) : null}

          <MoreOptionsMobileLink
            isActive={isActive('/marketing')}
            onClick={() => sendEvent('Contractors/Marketing-Clicked')}
            to={'/marketing'}>
            <QrCodeScannerIcon />
            Marketing
          </MoreOptionsMobileLink>
          <MoreOptionsMobileLink
            isActive={isActive('/calculator')}
            onClick={() => sendEvent('Contractors/Calculator-Clicked')}
            to={'/calculator'}>
            <CalculatorIconWhite />
            Calculator
          </MoreOptionsMobileLink>
        </MoreOptionsMobile>
      ) : null}

      <BottomNavMobile role="mobile_nav">
        <NavbarMobileLink
          isActive={isActive('/welcome')}
          onClick={() => sendEvent('Contractors/QuickStart-Clicked')}
          to={'/welcome'}>
          <BoltIcon />
          Start
        </NavbarMobileLink>

        <NavbarMobileLink
          isActive={isActive('/financing-estimates')}
          onClick={() => sendEvent('Contractors/Financing-Estimates-Clicked')}
          to={'/financing-estimates'}>
          <CalculatorIcon />
          Financing
        </NavbarMobileLink>

        <NavbarMobileLink
          isActive={isActive('/financing')}
          onClick={() => sendEvent('Contractors/Financing-Clicked')}
          to={'/financing'}>
          <ClientsIcon />
          Clients
        </NavbarMobileLink>

        <NavbarMobileLink
          isActive={isActive('/design')}
          onClick={() => sendEvent('Contractors/Design-Clicked')}
          to={'/design'}>
          <ArchitectureIcon />
          Design
        </NavbarMobileLink>

        <NavbarMobileLink
          role="mobile_nav_more_btn"
          isActive={() => showMore}
          onClick={onClickShowMore}
          to="">
          <MenuIcon />
          More
        </NavbarMobileLink>
      </BottomNavMobile>
    </>
  );
};

export default NavbarMobile;
