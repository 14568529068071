import {pick} from 'lodash';

import {
  gaParams,
  identifyLogRocket,
  initIntercom,
  setSentryUser,
} from '@renofi/analytics';
import {formatName} from '@renofi/utils/src/format';

const PROPS = [
  'contractorId',
  'firstName',
  'id',
  'lastName',
  'email',
  'phoneNumber',
];

export const setAnalyticsIdentify = (user = {}, extra = {}) => {
  const {id} = user || {};
  window.analytics?.identify(id, {...user, ...extra});
};

export const identifyAnalytcs = ({intercomIdentity, user} = {}) => {
  const {id} = user || {};
  const name = formatName(user);
  const traits = {
    name,
    ...pick(user, PROPS),
    ...gaParams(),
  };

  initIntercom({intercomIdentity, user});
  identifyLogRocket(id, traits);

  setAnalyticsIdentify(traits);
  setSentryUser(id, traits);
};
