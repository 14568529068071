import {getDomain, setItem} from '@renofi/utils';

export default (key, value, numberOfDays = 1) => {
  setItem({
    key,
    value,
    numberOfDays,
    domain: getDomain(),
  });
};
