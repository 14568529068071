import {Box, Image} from 'rebass';

import letterAssetSrc from '../img/letter-asset.svg';
import coBrandedLetterAssetSrc from '../img/co-branded-letter-asset.svg';
import qrCodeSrc from '../img/default-qr-code.png';

export const METADATA = {
  id: 'letter-flyer',
  pdfWidth: 161.925,
  pdfHeight: 209.55,
  fileName: 'letter-flyer',
  orientation: 'portrait',
};

const LetterFlyer = ({qrLink, logoUrl}) => {
  return (
    <Box id={METADATA.id} width={612} height={792} css={{position: 'relative'}}>
      {!logoUrl && (
        <>
          <Image
            size={85}
            src={qrLink || qrCodeSrc}
            css={{position: 'absolute', top: 309, right: 47}}
          />
          <Image src={letterAssetSrc} />
        </>
      )}
      {logoUrl && (
        <>
          <Image
            size={74}
            src={qrLink || qrCodeSrc}
            css={{
              position: 'absolute',
              top: 345,
              right: 53,
            }}
          />
          <Box
            width={306}
            height={259}
            css={{
              position: 'absolute',
              top: 59,
              left: 0,
            }}>
            <Image
              role="letter-flyer-logo"
              width={123}
              height="auto"
              src={logoUrl}
              css={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          </Box>
          <Image src={coBrandedLetterAssetSrc} />
        </>
      )}
    </Box>
  );
};

export default LetterFlyer;
