import {useContext, useState} from 'react';

import {Flex, Text, Image, Box} from 'rebass';
import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf';

import {
  lightBlue,
  darkBlue,
  fadeYellow,
  useScreenSize,
  blueGray,
} from '@renofi/utils';
import {Alert, Button, DownloadIcon} from '@renofi/components';

import AppContext from '../../context';
import lightBulbSrc from '../img/lightbulb.svg';

import AssetsPreview from './AssetsPreview';
import LetterFlyer, {METADATA as LETTER_FLYER_METADATA} from './LetterFlyer';
import PostcardFlyer, {
  METADATA as POSTCARD_FLYER_METADATA,
} from './PostcardFlyer';
import {AssetsColWrapper, AssetsColTitle, AssetsColDescription} from './styled';

const DownloadAssets = () => {
  const {isMobile} = useScreenSize();
  const {contractor} = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const qrLink = contractor?.marketingAssets.find(
    (asset) => asset.assetType === 'qr_code',
  )?.publicUrl;

  const downloadAssets = async () => {
    setLoading(true);
    for (const metadata of [LETTER_FLYER_METADATA, POSTCARD_FLYER_METADATA]) {
      const doc = new jsPDF({
        orientation: metadata.orientation,
        format: [metadata.pdfWidth, metadata.pdfHeight],
      });

      const flyer = document.getElementById(metadata.id);
      const letterCanvas = await html2canvas(flyer, {
        allowTaint: true,
        useCORS: true,
        scale: 10,
      });
      const letterUrl = letterCanvas.toDataURL('image/png');

      doc.addImage(
        letterUrl,
        'PNG',
        0,
        0,
        metadata.pdfWidth,
        metadata.pdfHeight,
      );
      doc.save(`${metadata.fileName}.pdf`);
    }
    setLoading(false);
  };

  return (
    <>
      <Box css={{position: 'absolute', left: '-9999px', top: 0}}>
        <LetterFlyer qrLink={qrLink} logoUrl={contractor?.logoUrl} />
        <PostcardFlyer qrLink={qrLink} logoUrl={contractor?.logoUrl} />
      </Box>
      <AssetsColWrapper
        role="download-assets"
        backgroundColor={darkBlue}
        css={{position: 'relative'}}>
        <Flex flexDirection="column" alignItems="center" css={{zIndex: 1}}>
          <AssetsColTitle css={{color: '#fff'}}>
            Marketing assets
          </AssetsColTitle>
          <AssetsColDescription css={{color: lightBlue}}>
            Share these materials with your clients to help them discover the
            financing options available to them.
          </AssetsColDescription>
          <AssetsPreview hasLogo={Boolean(contractor?.logoUrl)} />
          <Button
            xSmall={isMobile}
            css={{
              marginTop: 32,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 12,
            }}
            disabled={loading}
            loading={loading}
            onClick={downloadAssets}>
            <DownloadIcon color="#FFF" />
            Download Assets Package
          </Button>
          <Alert
            css={{
              marginTop: 24,
              backgroundColor: fadeYellow,
              border: 'none',
              maxWidth: 1214,
            }}>
            <Flex alignItems={['start', 'center']} css={{gap: 16}}>
              <Image flexShrink={0} src={lightBulbSrc} />
              <Text textAlign="left" color={darkBlue}>
                <strong>Tip:</strong> these materials contain a QR code unique
                to you, so when clients scan it they can be attributed to you
                and will appear in your clients report. You can print them and
                hand out to your clients or design your own using the same QR
                code.
              </Text>
            </Flex>
          </Alert>
        </Flex>
        <Box
          backgroundColor="#f2f9fe"
          width="100%"
          height={['45%', '38%']}
          css={{
            position: 'absolute',
            bottom: 0,
            zIndex: 0,
            borderRight: `1px solid ${blueGray}`,
          }}
        />
      </AssetsColWrapper>
    </>
  );
};

export default DownloadAssets;
