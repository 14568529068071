import {getDomain, setItem} from '../cookies';

export default function removeRefreshToken() {
  setItem({
    key: 'refreshToken',
    value: '',
    numberOfDays: -1,
    domain: getDomain(),
  });
}
