import React from 'react';

import {getEnvironmentUrl} from '@renofi/utils';

import {Layout, CTATopBar} from '../components';
import RequestClientFinancingModal from '../Financing/components/RequestClientFinancingModal';

const Calculator = () => {
  const host = getEnvironmentUrl('calculator', '4005');
  const url = `${host}/public/`;

  return (
    <>
      <Layout.AuthenticatedPage
        noPadding
        maxWidth={'100%'}
        pt={3}
        pb={0}
        mt={0}
        title={<CTATopBar p={3} />}>
        <iframe
          style={{
            border: 'none',
            width: '100%',
            height: '100%',
          }}
          src={url}
          title="Renovation Calculator"
          data-testid="calculator-iframe"
        />
      </Layout.AuthenticatedPage>
      <RequestClientFinancingModal />
    </>
  );
};

export default Calculator;
