import {noop} from 'lodash';

import {useCompleteRegistration} from '@renofi/api';
import {useNotifications} from '@renofi/components';

import useCompleteAuth from './useCompleteAuth';
import {REGISTRATION_ERRORS} from './constants';

export default () => {
  const {completeRegistration, loading} = useCompleteRegistration();
  const addNotification = useNotifications();
  const completeAuth = useCompleteAuth();

  const completeUserRegistration = async (
    token,
    onAuthSuccess = noop,
    onAuthError = noop,
  ) => {
    let rsp;
    try {
      rsp = await completeRegistration({variables: {token}});
      const {jwt, refreshToken, ...rest} =
        rsp?.data?.completeRegistration || {};

      if (!jwt || !refreshToken) {
        return addNotification(REGISTRATION_ERRORS.GENERIC_ERROR);
      }

      completeAuth({
        jwt,
        refreshToken,
        user: rest.user,
      });

      onAuthSuccess({rsp});
    } catch (err) {
      onAuthError(err);
      if (err.message?.includes('TOKEN_EXPIRED_ERROR')) {
        addNotification(REGISTRATION_ERRORS.TOKEN_EXPIRED_ERROR);
      } else {
        addNotification(REGISTRATION_ERRORS.GENERIC_ERROR);
      }
      return err;
    }

    return rsp;
  };

  return {loading, completeUserRegistration};
};
